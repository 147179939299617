import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import classNames from '../classNames'
import LaravelIcon from '../images/LaravelIcon.svg'
import ReactIcon from '../images/ReactIcon.svg'
import VueIcon from '../images/VueIcon.svg'
import AppleIcon from '../images/AppleIcon.svg'
import AndroidIcon from '../images/AndroidIcon_Alt.svg'
import WordPressIcon from '../images/WordPressIcon.svg'

export default function Logos({ backgroundColor, textColor }) {
  return (
    <div className={backgroundColor || "bg-teal-500"}>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className={classNames("text-center text-3xl font-extrabold", textColor || "text-white")}>A few of the things we work with</h2>
        <div className="flow-root self-center mt-8 lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between items-center self-center lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://laravel.com" target="_blank" rel="noopener noreferrer">
                <img className="h-16" src={LaravelIcon} alt="Laravel" />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                <img className="h-12" src={ReactIcon} alt="React" />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer">
                <img className="h-12" src={VueIcon} alt="VueJS" />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://www.apple.com/uk/ios" target="_blank" rel="noopener noreferrer">
                <img className="h-12" src={AppleIcon} alt="Apple" />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://www.android.com/intl/en_uk/" target="_blank" rel="noopener noreferrer">
                <img className="h-12" src={AndroidIcon} alt="Android" />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://wordpress.org/" target="_blank" rel="noopener noreferrer">
                <img className="h-16" src={WordPressIcon} alt="WordPress" />
              </a>
            </div>
            {/* <div className="mt-4 ml-8 flex flex-shrink-0 lg:ml-4 flex-grow lg:flex-grow-0">
              <a href="https://aws.amazon.com/what-is-cloud-computing" target="_blank" rel="noopener noreferrer">
                <img className="h-12" src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
