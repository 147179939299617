import * as React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import FeatureGrid from "../components/feature-grid"
import Logos from '../components/logos'
import ContactForm from '../components/contact-form'
import { LightningBoltIcon, TagIcon, DesktopComputerIcon, EmojiHappyIcon, LightBulbIcon, CodeIcon, CheckIcon } from '@heroicons/react/outline'
import navigation from "../navigation"

const features = [
  {
    name: 'Efficient',
    description:
      'With our sites, apps or systems, the sole purpose is to help you achieve your goals, effectively and efficiently.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Intuitive UX',
    description:
      'We like to understand our clients\' use case and tailor their system to the main user journey.',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Creative Solutions',
    description:
      'From adding an excel macro to improve your day to day through to a fully fledged solution, there\'s always a way to innovate.',
    icon: LightBulbIcon,
  },
  {
    name: 'Reasonable Pricing',
    description:
      'We aim to work within any budget tailoring a solution to suit your needs and tackle what means most to you.',
    icon: TagIcon,
  },
  {
    name: 'Happy clients',
    description:
      'The thing that makes our job feel worthwhile is when a customer comes back to work with us again.',
    icon: EmojiHappyIcon,
  },
  {
    name: 'Clean Code',
    description:
      'We take pride in what we do and strive for the best. This not only makes your hosting costs cheaper but keeps us zen.',
    icon: CodeIcon,
  },
]

const features2 = [
  {
    name: 'Quality',
    description:
      'We strive to produce the highest quality work because we’re proud of what we do. This not only gives you an amazing end product but our upfront efforts provide better performance allowing for leaner hosting solutions and long term maintainability.',
    icon: CodeIcon,
  },
  {
    name: 'Scalable',
    description:
      'Operating ourselves as a scalable entity to meet any size project, we believe your site/platform needs to be scalable, allowing it to grow with you and cope with increasing demand without constant upgrades or downtime.',
    icon: CodeIcon,
  },
  {
    name: 'Responsive',
    description:
      'Not only in speed which is a key feature of any site but in style too. With most web traffic coming from mobile/tablet devices we build on a mobile first approach to ensure your site looks good no matter where your users are accessing it from.',
    icon: CodeIcon,
  },
  {
    name: 'Security',
    description:
      'Security shouldn’t be an afterthought. From inception through development then onto hosting we’ll ensure security best practices are followed so you can rest easy knowing your data is safe.',
    icon: CodeIcon,
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <Hero navigation={navigation} />

      <FeatureGrid header="What we do" tagline="Smart. Curious. Hands On." features={features}>
        We like to push the boundaries of what we do and strive to deliver the best solution for you.
        Whether it be providing something bespoke to meet your need in the digital space or work with what you have and build it up.
        From sitting down with you to establish your requirements to executing a fully formed design, we are at your service.
      </FeatureGrid>

      <div className="relative bg-gray-800">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=3067&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 lg:py-24 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-1 lg:pr-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:mr-auto lg:ml-0">
              <h2 className="leading-6 text-teal-400 font-semibold tracking-wide uppercase">Working with us</h2>
              <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                Experienced but flexible team
              </h3>
              <div className="mt-5 prose prose-teal text-gray-300">
                <p>
                  We are an experienced team with a vast selection of projects in our arsenal to look back on from previous endeavours however, we're not set in our ways.
                  No matter if it's a single page brochure site, mobile app or a full fledged platform we've got you covered.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-800">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 lg:py-24 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                Providing quality products
              </h3>
              <div className="mt-5 prose prose-teal text-gray-300">
                <p>
                  We want you to enjoy using our products and solutions.
                  They need to effectively meet your needs; a simple user journey is key to any experience.
                  Ultimately you are the boss but we will always try to encourage simplicity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 lg:py-32 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-teal-100 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base text-teal-400 font-semibold tracking-wide uppercase">Values &amp; Features</h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">
                Why choose us?
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-800" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      className="rounded-lg shadow-lg object-cover object-center"
                      src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
                      width={1184}
                      height={1376}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="prose prose-teal text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <ul role="list">
                  <li>
                    <h6 className="font-medium">Collaborate</h6>
                    <span className="text-sm">Working with you to understand the problem you are trying to solve</span>
                  </li>
                  <li>
                    <h6 className="font-medium">Innovate</h6>
                    <span className="text-sm">Find more innovative ideas and solutions for your project</span>
                  </li>
                  <li>
                    <h6 className="font-medium">Tailored</h6>
                    <span className="text-sm">Be it from scratch or extending something existing, our solution will be tailored to you</span>
                  </li>
                  <li>
                    <h6 className="font-medium">Pride</h6>
                    <span className="text-sm">We take pride in our work and we want you to be proud of it too</span>
                  </li>
                  <li>
                    <h6 className="font-medium">Budget</h6>
                    <span className="text-sm">We'll aim to find a solution that meets your needs and your budget</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-800">
        <div className="max-w-7xl mx-auto py-16 lg:py-32 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-100">Skilled Development Team</h2>
            <p className="mt-4 text-lg text-gray-300">
              Developing brand & digital experiences
            </p>
          </div>

          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
            {features2.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-teal-300" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-100">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <Logos backgroundColor="bg-white" textColor="text-teal-400" />

      <ContactForm />
    </Layout>
  )
}

export default IndexPage
